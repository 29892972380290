<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                Unique members
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Unique members
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                    <!-- <v-btn
                        @click="createAdminUser()"
                        class="btn btn-primary font-weight-bolder font-size-sm"
                        style="color: #fff;">
                        <i class="fa fa-plus"></i>
                        Add admin user
                    </v-btn> -->
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="12" md="3">
                  <v-text-field 
                    label="Search by name or email"
                    v-model="search.name" 
                    outlined 
                    dense
                    v-on:keyup.enter="searchUniqueMembers()"
                    @input="search.name = $event !== null ? $event : ''" 
                    clearable
                  ></v-text-field>
                </v-col>

                <!--<v-col cols="12" sm="12" md="3" v-if="currentUser.access_type != 'score'">
                  <v-select
                      label="SCORE"
                      v-model="search.score_id"
                      :items="scores"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      v-on:keyup.enter="searchUniqueMembers()"
                      @input="search.score_id = $event !== null ? $event : ''"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <v-select 
                    :items="search_statuses" 
                    v-model="search.status" 
                    label="Status" 
                    item-text="name" 
                    item-value="value" 
                    v-on:keyup.enter="searchUniqueMembers()"
                    @input="search.status = $event !== null ? $event : ''" 
                    outlined 
                    clearable
                    dense
                  ></v-select>
                </v-col>-->
                
                <v-col cols="12" sm="12" :md="currentUser.access_type != 'score' ? 3 : 9">
                  <v-btn 
                    @click="searchUniqueMembers()" 
                    :loading="loading" 
                    class="btn btn-primary w-35 float-right"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
        
            <div class="table-responsive">
              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Surname</th>
                  <th class="px-3">First name</th>
                  <th class="px-3">UniqueID</th>
                  <th class="px-3">Contact</th>
                  <th class="px-3">Score</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in users" :key="index">
                    <td class="px-2">
                      <span>
                        {{ item.last_name }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span>
                        {{ item.first_name }}
                      </span>
                    </td>
                    <td class="px-2">
                      <div>
                        <span>
                          {{ item.unique_identifier }}
                        </span>
                      </div>
                    </td>
                    <td>
                        <a class="text-info mb-1" :href="'mailto:'+item.email">
                            {{item.email ? item.email : 'NA' }}
                        </a><br/>
                        <a  class="text-info mb-1" :href="'tel:'+item.preferred_phone">
                            {{item.preferred_phone}}
                        </a>
                    </td>
                    <td class="px-2">
                      <span>
                        {{ item.score_short_name ? item.score_short_name : 'NA'}}
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="viewSummary(item.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                </span>
                                <span class="navi-text">Summary</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="users.length == 0">
                      <td colspan="5" class="text-center">
                          No items found
                      </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="users.length > 0"
                  class="pull-right mt-7"
                  @input="getUniqueMembers"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>

      <candidate-summary ref="view-summary"></candidate-summary>

    </div>
  </v-app>    
</template>

<script>
import UniqueMemberService from "@/services/user/unique-member/UniqueMemberService";
import ScoreService from "@/services/score/score/ScoreService";

const user = new UniqueMemberService();
const score = new ScoreService();

export default{
    data(){
        return{
            total: null,
            perPage: null,
            page: null,
            users: [],
            scores: [],
            currentUser: {},
            loading: true,
            search:{
                name: '',
                score_id: '',
                status: ''
            },
            search_statuses: [
                {name: 'Active', value: 1},
                {name: 'Inactive', value: 0},
            ],
        }
    },
    methods:{
      searchUniqueMembers(){
        this.page = 1;
        this.getUniqueMembers();
      },
      getCurrentUser() {
        this.currentUser =  this.$store.getters.currentUser;
      },
      getAllScore(){
        score
            .all()
            .then(response => {
              this.scores=response.data.scores;
            })
            .catch((err) => {});
      },
      getUniqueMembers(){
        this.loading = true;

        user
        .paginate(this.search, this.page)
        .then(response => {
            this.users=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {
        })
        .finally(() => {
            this.loading = false;
        });
      },
      viewSummary(userId){
        this.$router.push({
          name: 'user-candidates',
          params: {
            userId: userId
          }
        });
      }
  },
  mounted(){
    this.getUniqueMembers();
    this.getAllScore();
    this.getCurrentUser();
  }
}
</script>
