import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class UniqueMemberService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/unique-member';
    }

    // all() {
    //     let url = `${this.#api}/get/all`;
    //     return apiService.get(url);
    // }

    paginate(data={},index = null) {

        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    search(data={}, scoreId){
        let url = `${this.#api}/search/all?score_id=${scoreId}`;
        let param = {params: data};
        return apiService.query(url, param);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
}